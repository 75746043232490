const dataLargeSalesTeams = [
  {
    "Account Name": "GlobalData Plc",
    Website: "http://www.globaldata.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "N26",
    Website: "http://www.n26.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Orion",
    Website: "http://www.oriontechnologies.co.nz",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 3,
  },
  {
    "Account Name": "Adyen",
    Website: "http://www.adyen.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Mambu",
    Website: "http://www.mambu.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Odoo",
    Website: "http://www.odoo.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Signavio",
    Website: "http://www.signavio.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Klarna",
    Website: "http://www.klarna.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Trustpilot",
    Website: "http://www.trustpilot.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 2,
  },
  {
    "Account Name": "Horizon",
    Website: "http://www.horizontherapeutics.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Deliveroo",
    Website: "http://www.deliveroo.co.uk",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 3,
  },
  {
    "Account Name": "Socialbakers",
    Website: "http://www.socialbakers.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Infinity",
    Website: "http://www.infinity.co",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Adzuna",
    Website: "http://www.adzuna.co.uk",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "commercetools",
    Website: "http://www.commercetools.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 3,
  },
  {
    "Account Name": "Personio",
    Website: "http://www.personio.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 2,
  },
  {
    "Account Name": "Sendinblue",
    Website: "http://www.sendinblue.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "ContentSquare",
    Website: "http://www.contentsquare.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Open Source",
    Website: "http://www.ost-linux.co.uk",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Mirakl",
    Website: "http://www.mirakl.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Spotify",
    Website: "http://www.lifeatspotify.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 3,
  },
  {
    "Account Name": "Infobip",
    Website: "http://www.infobip.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "LeanIX",
    Website: "http://www.leanix.net",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Fenergo",
    Website: "http://www.fenergo.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Bbc",
    Website: "http://www.bbc-tech.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Typeform",
    Website: "http://www.typeform.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "BrowserStack",
    Website: "http://www.browserstack.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 3,
  },
  {
    "Account Name": "BLUE",
    Website: "http://www.bt-blue.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 3,
  },
  {
    "Account Name": "TravelPerk",
    Website: "http://www.travelperk.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 2,
  },
  {
    "Account Name": "PayFit",
    Website: "http://www.payfit.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 3,
  },
  {
    "Account Name": "Delivery Hero",
    Website: "http://www.deliveryhero.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 2,
  },
  {
    "Account Name": "Matillion",
    Website: "http://www.matillion.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Gymshark",
    Website: "http://www.gymshark.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Criteo",
    Website: "http://www.criteo.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 3,
  },
  {
    "Account Name": "FARFETCH",
    Website: "http://www.farfetch.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Spendesk",
    Website: "http://www.spendesk.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Neptune",
    Website: "http://www.neptune-software.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "NXP",
    Website: "http://www.nxp.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "EVO",
    Website: "http://www.evo-group.co.uk",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Asg",
    Website: "http://www.asgcrm.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Onfido",
    Website: "http://www.onfido.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Showpad",
    Website: "http://www.showpad.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Darktrace",
    Website: "http://www.darktrace.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 3,
  },
  {
    "Account Name": "inriver",
    Website: "http://www.inriver.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Cti",
    Website: "http://www.clougnitive.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Indigo",
    Website: "http://www.indigotg.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Aiven",
    Website: "http://www.aiven.io",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Camunda",
    Website: "http://www.camunda.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Beacon",
    Website: "http://www.beacon.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 2,
  },
  {
    "Account Name": "GoCardless",
    Website: "http://www.gocardless.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 3,
  },
  {
    "Account Name": "Origami",
    Website: "http://www.origamienergy.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Doctolib",
    Website: "http://www.doctolib.fr",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Cleo",
    Website: "http://www.meetcleo.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Pricefx",
    Website: "http://www.pricefx.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Penta",
    Website: "http://www.getpenta.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Uberall",
    Website: "http://www.uberall.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Arc",
    Website: "http://www.arcandco.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Staffbase",
    Website: "http://www.staffbase.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Bolt",
    Website: "http://www.bolt.eu",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "LumApps",
    Website: "http://www.lumapps.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "SumUp",
    Website: "http://www.sumup.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Bim",
    Website: "http://www.bim-about.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "PrestaShop",
    Website: "http://www.prestashop.com",
    "Enterprise Segment (Yes/Blank)": "",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 1,
  },
  {
    "Account Name": "Akeneo",
    Website: "http://www.akeneo.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Bynder",
    Website: "http://www.bynder.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 3,
  },
  {
    "Account Name": "DocPlanner",
    Website: "http://www.docplanner.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Sana",
    Website: "http://www.sanalabs.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 2,
  },
  {
    "Account Name": "Mint",
    Website: "http://www.mintsoftwaresystems.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Relex",
    Website: "http://www.relexsolutions.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Welcome to the Jungle France",
    Website: "http://www.welcometothejungle.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Snyk",
    Website: "http://www.snyk.io",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "FSB",
    Website: "http://www.fsbtech.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "SALESmanago",
    Website: "http://www.salesmanago.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Hopin",
    Website: "http://www.hopin.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 3,
  },
  {
    "Account Name": "Templafy",
    Website: "http://www.templafy.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "AB Tasty",
    Website: "http://www.abtasty.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Red Points",
    Website: "http://www.redpoints.com",
    "Enterprise Segment (Yes/Blank)": "",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Hive",
    Website: "http://www.hive.app",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Storyblok",
    Website: "http://www.storyblok.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Currencycloud",
    Website: "http://www.currencycloud.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Platform.sh",
    Website: "http://www.platform.sh",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Sinch",
    Website: "http://www.sinch.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 3,
  },
  {
    "Account Name": "Egress",
    Website: "http://www.egress.com",
    "Enterprise Segment (Yes/Blank)": "",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 1,
  },
  {
    "Account Name": "Hive",
    Website: "http://www.hivehome.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "CloudFactory",
    Website: "http://www.cloudfactory.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 3,
  },
  {
    "Account Name": "Truphone",
    Website: "http://www.truphone.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Pexip",
    Website: "http://www.pexip.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Ardoq",
    Website: "http://www.ardoq.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Covestro",
    Website: "http://www.covestro.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Livestorm",
    Website: "http://www.livestorm.co",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Wise",
    Website: "http://www.wise.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Prisma",
    Website: "http://www.prisma.io",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Brandwatch",
    Website: "http://www.brandwatch.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 2,
  },
  {
    "Account Name": "Software Group",
    Website: "http://www.softwaregroup.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 2,
  },
  {
    "Account Name": "Gett",
    Website: "http://www.gett.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Starling Bank",
    Website: "http://www.starlingbank.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Deliverect",
    Website: "http://www.deliverect.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Rad",
    Website: "http://www.radcorporation.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Thought Machine",
    Website: "http://www.thoughtmachine.net",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Quantexa",
    Website: "http://www.quantexa.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Pyramid Analytics",
    Website: "http://www.pyramidanalytics.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Openbravo",
    Website: "http://www.openbravo.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Rydoo",
    Website: "http://www.rydoo.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Philip Morris International",
    Website: "http://www.pmi.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Paddle",
    Website: "http://www.paddle.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 3,
  },
  {
    "Account Name": "Glofox",
    Website: "http://www.glofox.com",
    "Enterprise Segment (Yes/Blank)": "",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 1,
  },
  {
    "Account Name": "GetYourGuide",
    Website: "http://www.getyourguide.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "BrandMaker",
    Website: "http://www.brandmaker.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Redgate",
    Website: "http://www.leanix.net",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Funding Circle",
    Website: "http://www.fundingcircle.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 2,
  },
  {
    "Account Name": "Bambuser",
    Website: "http://www.bambuser.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Sonar",
    Website: "http://www.sonarsource.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Proactive",
    Website: "http://www.proactiveinvestors.co.uk",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "MessageBird",
    Website: "http://www.messagebird.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 3,
  },
  {
    "Account Name": "TechnipFMC",
    Website: "http://www.technipfmc.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Ethereum",
    Website: "http://www.ethereum.org",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Perkbox",
    Website: "http://www.perkbox.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Telemedicine",
    Website: "http://www.tentelemed.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Nosto",
    Website: "http://www.nosto.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Plum",
    Website: "http://www.withplum.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 3,
  },
  {
    "Account Name": "Broadpeak",
    Website: "http://www.broadpeak.tv",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Talkwalker",
    Website: "http://www.talkwalker.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "LeadDesk",
    Website: "http://www.leaddesk.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Payhawk",
    Website: "http://www.payhawk.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 2,
  },
  {
    "Account Name": "Tink",
    Website: "http://www.tink.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 2,
  },
  {
    "Account Name": "Agicap",
    Website: "http://www.agicap.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Zoopla",
    Website: "http://www.zoopla.co.uk",
    "Enterprise Segment (Yes/Blank)": "",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "wejo",
    Website: "http://www.wejo.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Glassbox",
    Website: "http://www.glassbox.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Huboo",
    Website: "http://www.huboo.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Reward Gateway",
    Website: "http://www.rewardgateway.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "Yes",
    "# segments": 2,
  },
  {
    "Account Name": "TrueLayer",
    Website: "http://www.truelayer.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Grosvenor",
    Website: "http://www.grosvenor.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "trivago",
    Website: "http://www.trivago.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Swapcard",
    Website: "http://www.swapcard.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Gwi",
    Website: "http://www.gwi.com",
    "Enterprise Segment (Yes/Blank)": "",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Darwin",
    Website: "http://www.darwininteractive.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "OVO",
    Website: "http://www.ovo.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Deezer",
    Website: "http://www.deezer.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "DAZN",
    Website: "http://www.dazn.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "MotorK",
    Website: "http://www.motork.io",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Keepit",
    Website: "http://www.keepit.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Lightyear",
    Website: "http://www.lightyear.one",
    "Enterprise Segment (Yes/Blank)": "",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Trustly",
    Website: "http://www.trustly.net",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Purple",
    Website: "http://www.purple.ai",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Clarivate",
    Website: "http://www.clarivate.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "Yes",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 2,
  },
  {
    "Account Name": "Babbel",
    Website: "http://www.babbel.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
  {
    "Account Name": "Bitpanda",
    Website: "http://www.bitpanda.com",
    "Enterprise Segment (Yes/Blank)": "Yes",
    "Mid-market segment (Yes/Blank)": "",
    "SMB Segment (Yes/Blank)": "",
    "# segments": 1,
  },
]

export default dataLargeSalesTeams
