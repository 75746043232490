import React from "react"

import DatasetDisplay from "../components/DatasetDisplay"

// content specific imports
import dataLargeSalesTeams from "../data/data-large-sales-teams"
import ogImage from "../images/data-points/300-sales-teams.png"

// tanstack table
import { createColumnHelper } from "@tanstack/react-table"

const columnHelper = createColumnHelper()
const columns = [
  columnHelper.accessor(row => row["Account Name"], {
    id: "accountName",
    cell: info => (
      <div className="max-w-[100px] truncate">
        <a
          href={info.row.original["domain"]} rel="nofollow"
          target="_blank"
          className="text-blue underline truncate"
        >
          {info.getValue()}
        </a>
      </div>
    ),
    header: "Company",
  }),
  columnHelper.accessor("# segments", {
    cell: info =>
      info.getValue() === 2 ? (
        <span className="text-xs border-yellow-500 bg-yellow-100 border rounded px-1 shadow">
          2
        </span>
      ) : info.getValue() === 3 ? (
        <span className="text-xs border-purple-500 bg-purple-100 border rounded px-1 shadow">
          3
        </span>
      ) : (
        <span className="text-xs border rounded px-1 shadow">1</span>
      ),
  }),
  columnHelper.accessor("Enterprise Segment (Yes/Blank)", {
    cell: info =>
      info.getValue() === "Yes" && (
        <span className="text-xs text-emerald-800 bg-emerald-50 rounded px-1">
          Enterprise Focus
        </span>
      ),
    header: "Sells to Enterprise?",
  }),
  columnHelper.accessor("Mid-market segment (Yes/Blank)", {
    cell: info =>
      info.getValue() === "Yes" && (
        <span className="text-xs text-yellow-800 bg-yellow-50 rounded px-1">
          Mid-market Focus
        </span>
      ),
    header: "Sells to Mid Market?",
  }),
  columnHelper.accessor("SMB Segment (Yes/Blank)", {
    cell: info =>
      info.getValue() === "Yes" && (
        <span className="text-xs text-pink-800 bg-pink-50 rounded px-1">
          SMB Focus
        </span>
      ),
    header: "Sells to SMB?",
  }),
]

const dataContext = {
  title: "150 enterprise sales teams in Europe",
  description:
    "Which venture backed companies have the largest and most sophisticated enterprise sales teams? We asked Tactic to find out. Do they sell to enterprise, mid-market, SMB, or all three?",
  blurb:
    "Which venture backed companies have the largest and most sophisticated enterprise sales teams? We asked Tactic to find out. Not only did we look at the size of these teams, but also their focus: do they sell to enterprise, mid-market, SMB, or all three?",
  ctaText:
    "Get the whole list of 150 companies with enterprise sales teams and sync it with your CRM.",
  slug: "150-european-enterprise-sales-teams",
  freeLimit: 50,
  blogPost: (
    <>
      <p>
        Not only is this question interesting to us at Tactic, we also find that
        a whooping 15% of our target accounts sell into a sales/marketing
        persona.
      </p>
      <p>However, not all revenue teams are made the same way.</p>
      <p>We researched 3,000+ European VC backed scale ups to ask:</p>
      <p>👉 Where are the biggest sales teams?</p>
      <p>👉 Do they sell to enterprise, mid-market, or SMB?</p>
      <p>👉 Who sells to all 3 segments?</p>
      <h2 id="what-do-the-numbers-say-">What do the numbers say?</h2>
      <ul>
        <li>
          30% of european VC backed scale ups have B2B sales teams. That’s a
          whooping 900 teams!
        </li>
        <li>
          35 companies focus explicitly on all 3 segments – including Payfit,
          Deliveroo, Darktrace and GoCardless
        </li>
        <li>
          97 companies focus on all 2 segments – including Adyen, Matillion, and
          Alma
        </li>
      </ul>
      <h2 id="where-did-the-answers-come-from-">
        Where did the answers come from?
      </h2>
      <p>🤔 You might be asking, how do we know this?</p>
      <p>
        Well its pretty simple. For say the enterprise segment, we asked Tactic
        to count
      </p>
      <ul>
        <li>
          number of people in a company with the words &#39;enterprise&#39; in
          their job titles
        </li>
        <li>
          number of job posts hiring for &#39;enterprise sales&#39;,
          &#39;enterprise AE&#39;, &#39;strategic AE&#39;, &#39;sales
          director&#39; and similar
        </li>
      </ul>

      <h2 id="data" className="scroll-mt-24">
        Data
      </h2>
      <p>
        Here's the first 50 companies in this dataset. If you'd like to ask
        additional questions (e.g. who has monthly pricing?), integrate this
        into your CRM, or access the rest of the dataset, please{" "}
        <a href="#cta">contact us for a chat</a>!
      </p>
    </>
  ),
  columns: columns,
  published: "Dec 1, 2022",
}

const DataSet = ({ location }) => {
  return (
    <DatasetDisplay
      location={location}
      ogImage={ogImage}
      dataset={dataLargeSalesTeams}
      dataContext={dataContext}
    />
  )
}

export default DataSet
